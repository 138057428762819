export const countries = {
  USA: 'US',
  Australia: 'AU',
  Spain: 'ES',
  'Czech-Republic': 'CZ',
  UK: 'UK',
  Estonia: 'EE',
  Argentina: 'AR',
  Mexico: 'MX',
  Canada: 'CA',
  NewZealand: 'NZ',
  Poland: 'PL',
  SouthAfrica: 'SA',
  Denmark: 'DK',
}

export const emailRegexp = new RegExp(
  /^(\D)+(\w)*((\.(\w)+)?)+@(\D)+(\w)*((\.(\D)+(\w)*)+)?(\.)[a-z]{2,}$/i
)
export const aboutText = `Every day we are faced with different financial decisions and
                  Path wants to be there for you. We hope to provide you the
                  tools you need to move forward with confidence. Whether it’s
                  financial products like personal loans, credit cards, or
                  insurance we’re here to help you take that next step with your
                  financial path!`
export const aboutHeader = `Straight forward writing that helps you simply make better
                  financial decisions.`

export const adverseUrl = 'https://adverse.lincx.la/ad'

export const upushKey = 'w5a8fZs0v2qbnuFLdoCAH36kxRzWKPU4'
